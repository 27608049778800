<template>
  <main class="page-main-bg" id="potentialClints">
    <v-container class="main-container position-relative">
      <tool-bar
        v-bind="toolBarProps"
        @toggleCards="cardsInfoState = !cardsInfoState"
      />

      <!-- filter -->
      <ClientFilter
        ref="filter"
        :availableFilters="availableFilters"
        @switched="switched"
      />
      <div class="d-flex mt-4">
        <MultipleSelectionsForDelete
          ref="MultipleSelectionsForDelete"
          v-bind="multipleSelectionsData"
          :showRestoreDeleted="false"
          @changeSelect="selectAllChanged"
          @switched="switched"
        />
      </div>

      <!-------------Loader---------------->
      <Loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />

      <!-------------------------------------------->
      <!---------- If no there any client ---------->
      <!-------------------------------------------->
      <v-col v-else-if="!userLength">
        <v-sheet
          class="pa-10 d-flex align-center justify-center flex-column bg-primary"
          height="60vh"
        >
          <span class="grey--text text--lighten-1 mb-4" style="font-size: 80px">
            <img
              src="@/assets/img/potential-clients.png"
              alt="Hectar Icon"
              width="50"
              loading="lazy"
            />
          </span>
          <h3 class="grey--text text--darken-3 my-0" v-text="noData.title" />
          <h5 class="grey--text" v-text="noData.subTitle" />
        </v-sheet>
      </v-col>

      <!-- dataTabel -->
      <v-row v-else class="mt-2">
        <v-col class="property-col" cols="12">
          <PCDataTable
            ref="dataTable"
            @convert-to-client="convertToClient"
            @del-client="deleteClient"
            @show-requests="goToRequests"
            :clients="clients"
            :headers="headers"
            :isCustomer="isCustomer"
          />
        </v-col>
      </v-row>

      <!----------------------------->
      <!------ Add client modal ----->
      <!----------------------------->
      <AddPTModal
        ref="AddPTModal"
        :dialogState="dialogState"
        :types="PTTypes"
        @close-modal="closeDialog"
        @add-new-user="addNewPtClient"
      />

      <!------------------------------>
      <!--------Pagination------------>
      <!------------------------------>

      <div class="text-center mt-6 mb-5" v-show="viewPagination">
        <Pagination :pagination="pagination" @onChange="getPTClinents" />
      </div>

      <!------------------------------------------------------->
      <!-------User actions (addClient & fastActions)---------->
      <!------------------------------------------------------->

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          @click="openAddDialog"
          data-test="open-add-modal"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img
            class="mx-2"
            src="../assets/img/svgs/001-writing.svg"
            loading="lazy"
          />
          <span class="mb-1">إضافة عميل محتمل</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions :class="{ 'mb-5': !hasPermission }" />
      </div>
    </v-container>
  </main>
</template>

<script>
// @TODO: Delte not working from BE
import ToolBar from '@/components/listing/ToolBar'
import ClientFilter from '@/components/potential-clints/ClintesFilter.vue'
import setView from '@/mixins/setView'
import multipleSelections from '@/mixins/multipleSelections'
import { userService } from '@/services'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import PCDataTable from '@/components/potential-clints/PCDataTable.vue'
import fastActions from '@/components/helper/fastActions'
import Loader from '@/components/helper/loader.vue'
import Pagination from '@/components/helper/pagination.vue'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { buildQueryString } from '@/utils/general'
import { breadcrumbs, noData, pagination, headers } from '@/constans/ptClients'
export default {
  name: 'potentialClints',
  components: {
    ToolBar,
    ClientFilter,
    PCDataTable,
    Loader,
    fastActions,
    MultipleSelectionsForDelete,
    Pagination,
    AddPTModal: () => import('@/components/potential-clints/AddPTModal.vue')
  },
  data() {
    return {
      clients: [],
      gridView: false,
      breadcrumbs,
      noData,
      pagination,
      headers,
      // to send new client data
      PTTypes: [],
      isPageLoading: false,
      cardsInfoState: true,
      clientSwitcher: false,
      availableStatusesFilters: [],
      clientsCount: 0,
      dialogState: false,
      filterQuery: {
        search: null
      }
    }
  },
  mixins: [setView, multipleSelections],
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        length: this.clients.length,
        cardsInfoState: this.cardsInfoState,
        title: 'العملاء المحتملين'
      }
    },
    userLength() {
      return !!this.clients.length
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    hasPermission() {
      return this.$hasPermission('users', 'clients', 'add')
    },
    availableFilters() {
      return [
        {
          nameAr: 'الكل',
          nameEn: 'count',
          role: 'الكل',
          _id: '',
          count: this.clientsCount
        },
        ...this.availableStatusesFilters
      ]
    },
    /* Multiple selections */
    // ------------------ //
    // For multiple selection delete and restore
    deleteTitle() {
      return this.showSelectAll ? 'حذف العملاء' : 'حذف العميل'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع العملاء المحذوفين'
        : 'استرجاع العميل المحذوف'
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    changeGridView(status) {
      this.gridView = status
    },
    switched(data) {
      this.clients = []
      this.pagination.pageNumber = 1
      const { name, value } = data

      this.clientSwitcher = value
    },
    goToRequests(client) {
      this.$router.push({ name: 'maintenance', query: { client: client.name } })
    },
    filterChanged({ code, search }) {
      this.filterQuery.code = code === 'الكل' ? '' : code
      this.filterQuery.search = search ?? ''
      this.pagination.pageNumber = 1
      this.getPTClinents()
    },
    closeDialog() {
      this.dialogState = false
    },
    openAddDialog() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة عملاء',
          color: 'error'
        })
        return
      }

      this.dialogState = true
    },
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.count / this.pagination.pageSize
      )
      this.pagination.count = res.count
    },
    async getPTClinents() {
      this.isPageLoading = true
      const query = buildQueryString(
        {
          pageNumber: this.pagination.pageNumber,
          pageSize: this.pagination.pageSize,
          environmentId: this.currentEnv._id,
          ...(this.filterQuery.search && { search: this.filterQuery.search }),
          ...(this.filterQuery.code && { role: this.filterQuery.code })
        },
        ''
      )
      try {
        const { data } = await userService.getPotentialClients(query)
        const {
          data: { result }
        } = await userService.getPTClientsCountByStatus(this.currentEnv._id)
        this.clients = data.users.map((client) => ({
          ...client,
          checked: false
        }))
        this.clientsCount = data.count
        this.availableStatusesFilters = result
        this.recalculatePagination(data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العملاء المحتملين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async addNewPtClient(newClient) {
      try {
        const payLoad = {
          isLead: true,
          ...newClient
        }
        await userService.addPotentialClient(payLoad)
        this.addNotification({
          text: 'تم اضافة العميل بنجاح',
          color: 'success'
        })
        this.pagination.pageNumber = 1
        this.closeDialog()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من اضافة العميل',
          color: 'error'
        })
      } finally {
        await this.getPTClinents()
      }
    },
    async getPtTypes() {
      this.isPageLoading = true
      try {
        const {
          data: { POTENTIAL_CLIENT_TYPES }
        } = await userService.getPotentialClientTypes()
        this.PTTypes = POTENTIAL_CLIENT_TYPES
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل البيانات',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },

    async convertToClient(clientId) {
      this.isPageLoading = true
      const body = {
        id: clientId,
        environment: this.currentEnv._id
      }
      try {
        await userService.converPtToClinet(body)
        this.addNotification({
          text: 'تم اضافة العميل بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحويل العميل',
          color: 'error'
        })
      } finally {
        await this.getPTClinents()
        this.isPageLoading = false
      }
    },
    async deleteClient(clientIds) {
      this.isPageLoading = true
      const body = {
        ids: clientIds,
        environment: this.currentEnv._id
      }
      try {
        await userService.deletePotentialClients(body)
        this.addNotification({
          text: 'تم حذف العملاء بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من حذف العملاء',
          color: 'error'
        })
      } finally {
        await this.getPTClinents()
        this.isPageLoading = false
      }
    },
    // Delete clients with multiple selections
    async deleteItems() {
      const title = this.showSelectAll ? 'حذف العملاء' : 'حذف العميل'
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف العملاء وعددهم ${this.getAllChecked.length} يرجى العلم انه يمكن لا يتم حذفهم اذا كان لديهم عقارات`
        : 'هل انت متاكد من حذف العميل يرجى العلم انه يمكن لا يتم حذفه اذا كان لدية طلبات'

      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title,
        message,
        options: {
          color: 'red'
        }
      })

      // 2 - If confirmed
      if (status) {
        try {
          const body = {
            ids: this.getAllChecked,
            environment: this.currentEnv._id
          }
          await userService.deletePotentialClients(body)
          this.addNotification({
            text: 'تم الحذف بنجاح',
            color: 'success'
          })
          await this.getPTClinents()
        } catch {
          this.addNotification({
            text: 'لا يمكنك حذف العميل(العملاء)، يجب إكمال تنفيذ طلباته أولا',
            color: 'error'
          })
        }
      }
    }
  },
  async mounted() {
    this.changeGridView(true)
    await this.getPTClinents()
    await this.getPtTypes()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
